<template>
  <div
    class="vz-skeleton"
    :class="skeletonClass"
    :style="skeletonStyle"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { PropType, StyleValue } from 'vue';
import { VzClass } from '~/types/types';

const props = defineProps({
  type: {
    type: String as PropType<'block'|'rounded'|'circle'|'input'>,
    default: 'block'
  },
  animated: {
    type: Boolean,
    default: true
  },
  height: {
    type: [String, Number],
    default: 0
  },
  width: {
    type: [String, Number],
    default: 0
  },
})

const skeletonClass = computed((): VzClass => {
  return {
    'vz-skeleton-animated': props.animated,
    [`vz-skeleton-${props.type}`]: props.type
  }
})

const skeletonStyle = computed((): StyleValue => {
  if (!props.height) {
    return ''
  }
  const result: any = {
    'min-height': `${props.height}px`,
    height: `${props.height}px`
  }
  if (props.type === 'circle') {
    result.width = `${props.height}px`
  }
  if (props.width) {
    result.width = `${props.width}px`
  }
  return result
})
</script>
